jQuery(document).ready(function($) {
    var originalContent = $('.search-results').html();
    var debounceTimeout;
    var minimumLength = 3;
    var cache = {};
    var currentPage = 1;

    function toggleLoadMoreButton(totalPosts) {
        if (totalPosts > 10) {
          $('#load-more').show();
        } else {
          $('#load-more').hide();
        }
      }
  
    $('#live-search-field').on('keyup', function() {
        var searchValue = $(this).val();
        currentPage = 1;
    
        if(searchValue === '' || searchValue.length < minimumLength) {
            $('.search-results').html(originalContent);
            toggleLoadMoreButton(0);
            return;
        }
      
        clearTimeout(debounceTimeout);
      
        debounceTimeout = setTimeout(function() {
      
            if(cache[searchValue]) {
                if (cache[searchValue].content) {
                    $('.search-results').html(cache[searchValue].content);
                }
                return;
            }
      
            $.ajax({
                url: myAjax.ajaxurl,
                type: 'POST',
                data: {
                    action: 'my_search',
                    term: searchValue,
                    nonce: myAjax.nonce
                },
                success: function(response) {
                    cache[searchValue] = response;
                    if (response.content) {
                        $('.search-results').html(response.content);
                    } else {
                        $('.search-results').html('<div class="search-results full-height">Nenhum resultado encontrado.</div>');
                    }
                    toggleLoadMoreButton(response.totalPosts);
                },
                error: function(errorThrown) {
                    console.error("Error: ", errorThrown);
                },
                dataType: 'json'
            });
        }, 500);
      });
      
  
    $('#load-more').on('click', function() {
      currentPage++;
      $.ajax({
        url: myAjax.ajaxurl,
        type: 'POST',
        data: {
          action: 'my_search',
          term: $('#live-search-field').val(),
          nonce: myAjax.nonce,
          paged: currentPage
        },
        success: function(response) {
          if (response.content) {
            $('.posts').append(response.content);
          }
  
          if (!response.hasMore) {
            $('#load-more').hide();
          }
        },
        error: function(errorThrown) {
          console.error("Error: ", errorThrown);
        },
        dataType: 'json'
      });
    });

  $('#live-search-field').on('input', function() {
    if ($(this).val() === '') {
      currentPage = 1;
      $.ajax({
        url: myAjax.ajaxurl,
        type: 'POST',
        data: {
          action: 'my_search',
          term: '',
          nonce: myAjax.nonce,
          paged: currentPage
        },
        success: function(response) {
          $('.search-results').html(response.content);
          toggleLoadMoreButton(response.totalPosts);
        },
        dataType: 'json'
      });
    }
  });
  });
  